import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
type InitialState = {
  step: string | null
  isBiometryRetry: boolean
}

const initialState = {
  step: null,
  isBiometryRetry: false
} as InitialState

const stepSlice = createSlice({
  name: 'stepSlice',
  initialState,
  reducers: {
    setNewStep: (state, { payload }: { payload: string }) => {
      state.step = payload
    },
    retryBiometry: (state, { payload }: { payload: boolean }) => {
      state.isBiometryRetry = payload
    }
  }
})

export const stepSelector = (state: RootState) => state.step.step
export const biometryRetrySelector = (state: RootState) => state.step.isBiometryRetry

export const { setNewStep, retryBiometry } = stepSlice.actions
export default stepSlice.reducer
