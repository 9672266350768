import { useNavigateToTheStep } from 'hooks/useNavigateToTheStep'
import i18n from 'i18n/config'
import StepLayout from 'layout/step-layout'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSetBiometryForensicsMutation } from 'redux/api/biometry.api'
import {
  isAuthenticatedSelector,
  landingTypeSelector,
  setAuthData,
  setIsAuthendicated,
  setLandingType,
  setSystemLanguage,
  systemLanguageSelector
} from 'redux/store/slices/application-slice'
import { errorSelector, setError } from 'redux/store/slices/error-slice'
import { stepSelector } from 'redux/store/slices/step-slice'
// import { wsSelector } from 'redux/store/slices/websocket-slice'
import ProtectedRoute from 'routes/ProtectedRoute'
import { pathnames } from 'utils/nr-steps'
import { queryParams } from 'utils/queryparams'
import { wsStatuses } from 'utils/ws-statuses'

import ErrorPage from './404'
import AddressPage from './steps/AddressPage'
import BiometryForensics from './steps/BiometryForensics'
import ForensicsSuccess from './steps/ForensicsSuccess'
// import Connecting from './steps/Connecting'
import InternalChecks from './steps/Internal-Checks'
import SignDoc from './steps/SignDoc'
import Success from './steps/Success'
import SumSub from './steps/SumSubPage'

function Steps() {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [navigateToNewStep] = useNavigateToTheStep()
  const navigate = useNavigate()

  const step = useSelector(stepSelector)
  const error = useSelector(errorSelector)
  const systemLanguage = useSelector(systemLanguageSelector)
  const landingType = useSelector(landingTypeSelector)

  const [setBiometryForensics, { isSuccess }] = useSetBiometryForensicsMutation()

  useEffect(() => {
    if (
      searchParams.get(queryParams.token) &&
      searchParams.get(queryParams.application_id) &&
      searchParams.get(queryParams.product_user_action_uuid)
    ) {
      dispatch(
        setAuthData({
          token: searchParams.get('token') ?? '',
          application_id: searchParams.get('application_id') ?? '',
          product_user_action_uuid: searchParams.get('product_user_action_uuid') ?? ''
        })
      )
      dispatch(setIsAuthendicated())
    }
  }, [searchParams, dispatch])

  useEffect(() => {
    searchParams.get(queryParams.language) &&
      dispatch(setSystemLanguage({ lang: (searchParams.get(queryParams.language) as 'ru' | 'kk' | 'en') ?? null }))
    searchParams.get(queryParams.type) &&
      dispatch(setLandingType({ type: searchParams.get(queryParams.type) as 'forensics' | 'sumsub' } ?? null))
  }, [searchParams, dispatch])

  useEffect(() => {
    step && navigateToNewStep()
  }, [step, isSuccess])

  useEffect(() => {
    if (error.code) {
      navigate(pathnames.error)
    }
  }, [error])

  useEffect(() => {
    systemLanguage && i18n.changeLanguage(systemLanguage)
  }, [systemLanguage])

  useEffect(() => {
    landingType === 'forensics' && setBiometryForensics({ method: 'LANDING' })
  }, [landingType])

  useEffect(() => {
    if (!parseInt(import.meta.env.NR_APPLY_OPEN)) {
      dispatch(setError({ code: 'APPLY_CLOSED', description: import.meta.env.NR_APPLY_CLOSED_TEXT_RU }))
      navigate(pathnames.error)
    }
  }, [import.meta.env.NR_APPLY_OPEN])

  // useEffect(() => {
  //   isSuccess && navigate(pathnames.forensics)
  // }, [isSuccess])

  return (
    <Routes>
      <Route element={<StepLayout />}>
        <Route element={<ProtectedRoute />}>
          <Route path={pathnames.forensics} element={<BiometryForensics />} />
          <Route path={pathnames.forensics_success} element={<ForensicsSuccess />} />
          <Route path={pathnames.sumsub} element={<SumSub />} />
          <Route path={pathnames['sign-doc']} element={<SignDoc />} />

          {/* <Route path={pathnames.internal_checks} element={<InternalChecks />} /> */}
          <Route path={pathnames.success} element={<Success />} />
        </Route>
        <Route path={pathnames.address} element={<AddressPage />} />
        <Route path={pathnames.error} element={<ErrorPage />} />
      </Route>

      {/* <Route path={pathnames.loading} element={<ConnectingPage />} /> */}
    </Routes>
  )
}

export default Steps
